import styled from "styled-components"

import { Container } from "../../theme/global-style"

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
`

export const Product = styled.div`
  max-width: 400px;
  margin: 30px 0;
  width: 100%;
  padding: 3px;
  box-shadow: rgb(0 0 0 / 20%) 0px 2px 1px -1px,
    rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px;

  .swiper-container {
    background-color: ${({ theme }) => theme.colors.muted};
  }

  .swiper-wrapper {
    max-height: 450px;
  }

  @media (min-width: ${({ theme }) => theme.screens.xsm}) {
  }

  @media (min-width: ${({ theme }) => theme.screens.md}) {
    margin: 20px;
  }
`

export const ProductContent = styled.div`
  padding: 0 15px;

  h3 {
    font-size: 1rem;
  }

  ul,
  p {
    color: ${({ theme }) => theme.colors.text};
  }

  ul {
    padding-left: 20px;
  }

  .item-price,
  .item-parcel,
  .item-off {
    margin: 0;
  }

  .item-price {
    font-size: ${({ theme }) => theme.fontSize.xl3};
  }

  .item-parcel {
    color: ${({ theme }) => theme.colors.green};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
  }

  .item-off {
    color: ${({ theme }) => theme.colors.secondary};
  }
`
